import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import App from "./App";
import ServiceSeeker from "./Components/ServiceSeeker";
import Login from "./Components/Login";
import HeaderAndDrawer from "./Components/Header";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Companies from "./Components/companies";
import Individuals from "./Components/Individuals";
import ServiceProviders from "./Components/ServiceProviders";
import Profile from "./Components/Profile";
import Posts from "./Components/Posts";
import Reviews from "./Components/Reviews";
import ServiceProvidersAOS from "./Components/ServiceProvidersAOS";

export default class RoutersComponent extends Component {
  render() {
    return (
      <div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <BrowserRouter>
          {window.location.pathname === "/" ? null : <HeaderAndDrawer />}
          <Switch>
            <Route exact={true} path="/" component={App} />
            {/* <Route exact={true} path="/login" component={Login}/>    */}
            <Route path="/service-seeker" component={ServiceSeeker} />
            <Route path="/companies" component={Companies} />
            <Route path="/individuals" component={Individuals} />
            <Route path="/service-provider" component={ServiceProviders} />
            <Route
              path="/service-provider-aos"
              component={ServiceProvidersAOS}
            />
            <Route path="/posts" component={Posts} />
            <Route path="/reviews" component={Reviews} />
            <Route path="/profile" component={Profile} />
          </Switch>
        </BrowserRouter>
      </div>
    );
  }
}
