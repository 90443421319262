import React, { Component, useEffect, useState, useCallback } from "react";
import axios from "axios";
import Header from "./Header";
import {
  Card,
  CardActions,
  CardMedia,
  CardActionArea,
  CardContent,
  Typography,
  Button,
} from "@material-ui/core";
import profile from "../../src/assets/profile.jpg";
import ScreenWrapper from "../ScreenWrapper";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import SearchBar from "./search";
import debounce from "lodash.debounce";
import {
  getAllServiceProviders,
  getAllServiceProvidersAOS,
  approveServiceproviderAOS,
  deleteServiceproviderAOS,
  filterServiceProvidersAOS,
} from "../Actions/admin/admin";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useHistory } from "react-router-dom";
import { authCheck } from "./../auth";
import moment from "moment";
import Grid from "@material-ui/core/Grid";

const useSortableData = (items, config = null) => {
  const [sortConfig, setSortConfig] = React.useState(config);
  console.log("items ==== ", items);
  const sortedItems = React.useMemo(() => {
    let sortableItems = [...items];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [items, sortConfig]);

  const requestSort = (key) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  return { items: sortedItems, requestSort, sortConfig };
};

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const ServiceProvidersAOS = (props) => {

  const [textcheck, settextcheck] = useState("Fatching Data....");
  useEffect(() => {
    const timer = setTimeout(() => {
      settextcheck("No Data Found")
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  const classes = useStyles();
  const { items, requestSort, sortConfig } = useSortableData(
    props?.serviceProviders ? props.serviceProviders : []
  );
  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  
  let history = useHistory();
  const [selectedRow, setSelectedRow] = useState("");

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (row) => {
    setOpen(true);
    setSelectedRow(row);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!authCheck()) {
      history.push("/");
      window.location.reload();
    } else {
      props.fetchServiceProvidersAOS();
    }
  }, []);

  const debounceFn = useCallback(
    debounce((_searchVal) => {
      props.filterServiceProvidersAOS(_searchVal);
      // send the server request here
    }, 1000),
    []
  );

  async function approveUser(selectedRow) {
    await props.approveServiceproviderUser(selectedRow._id, selectedRow);
  }

  async function approveAOS(selectedRow) {
    console.log("selectedRow ==== ", selectedRow);
    await props.approveServiceProviderAOS(selectedRow._id);
  }

  const deleteAOS = async (id) => {
    await props.deleteServiceproviderAOS(id);
  };

  async function onChange(event) {
    event.persist();
    console.log("event.target.value ==== ", event?.target?.value);
    debounceFn(event.target.value);
    // await props.filterPosts(value);
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"AOS Details"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Grid item xs={12} lg={12}>
              <p>
                {" "}
                <span> Service Provider ID : </span> {selectedRow._id}{" "}
              </p>
              <p>
                {" "}
                <span> Current Area of Specialization : </span>{" "}
                {selectedRow?.area_of_specialization?.join(", ")}{" "}
              </p>
              {selectedRow.old_specialization &&
                selectedRow.old_specialization.length > 0 && (
                  <p>
                    {" "}
                    <span> Old Area of Specialization : </span>{" "}
                    {selectedRow?.old_specialization?.join(", ")}{" "}
                  </p>
                )}

              <p>
                {" "}
                <span> Approved : </span>{" "}
                {selectedRow.approved ? "true" : "false"}
              </p>

              {/* <p>
                {" "}
                <span> Deleted : </span>{" "}
                {selectedRow.deleted ? "true" : "false"}
              </p>

              <p>
                {" "}
                <span> Active: </span> {selectedRow.active ? "true" : "false"}
              </p> */}

              {selectedRow.created_at && (
                <p>
                  {" "}
                  <span> Created At: </span>{" "}
                  {moment(selectedRow.created_at).format("YYYY-MM-DD HH:MM")}
                </p>
              )}
              <ButtonGroup
                variant="text"
                color="primary"
                aria-label="text primary button group"
              >
                {/* <Button onClick={() => suspendUser(user._id)}>Suspend</Button> */}
              </ButtonGroup>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          {!selectedRow.approved ? (
            <Button onClick={() => approveAOS(selectedRow)}>Approve</Button>
          ) : null}
        </DialogActions>
      </Dialog>

      <ScreenWrapper>
        <h2 style={{ textAlign: "center" }}> Service Provider AOS </h2>
        {/* <SearchBar onChange={onChange} /> */}

        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead className="tablehead">
              <TableRow>
                <TableCell>
                  Name
                  <button
                    type="button"
                    onClick={() => requestSort("name")}
                    className={getClassNamesFor("name")}
                  >
                    sort
                  </button>
                </TableCell>
                <TableCell align="right">
                  Email
                  <button
                    type="button"
                    onClick={() => requestSort("email")}
                    className={getClassNamesFor("email")}
                  >
                    sort
                  </button>
                </TableCell>
                {/* <TableCell align="right">
                  REGISTERED WITH
                  <button
                    type="button"
                    onClick={() => requestSort("registered_with")}
                    className={getClassNamesFor("registered_with")}
                  >
                    sort
                  </button>
                </TableCell> */}
                <TableCell align="right">
                  ACTIVE
                  <button
                    type="button"
                    onClick={() => requestSort("active")}
                    className={getClassNamesFor("active")}
                  >
                    sort
                  </button>{" "}
                </TableCell>
                <TableCell align="right">Actions </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {items && items.length === 0 ? 
          <TableRow>
          <TableCell colSpan={4} align="center"><h6>{textcheck}</h6></TableCell>
          </TableRow>
        : 
        <>
        {items.map((row) => (
                <TableRow key={row._id}>
                  <TableCell component="th" scope="row">
                    <Button onClick={() => handleClickOpen(row)}>
                    {row && row.service_provider && row.service_provider.name}
                    </Button>
                  </TableCell>
                  <TableCell align="right">
                    {row && row.service_provider && row.service_provider.email}
                  </TableCell>
                  {/* <TableCell align="right">{row.registered_with}</TableCell> */}
                  <TableCell align="right">
                    {row.active ? "true" : "false"}
                  </TableCell>
                  <TableCell align="right">
                    {" "}
                    <Button
                      onClick={() => approveAOS(row)}
                      disabled={row.approved}
                    >
                      {" "}
                      Approve{" "}
                    </Button>{" "}
                  </TableCell>
                </TableRow>
              ))}
        </>
        }
            </TableBody>
          </Table>
        </TableContainer>
      </ScreenWrapper>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    serviceProviders: state.adminReducer.serviceProvidersAOS,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchServiceProviders: () => dispatch(getAllServiceProviders()),
    fetchServiceProvidersAOS: () => dispatch(getAllServiceProvidersAOS()),
    approveServiceProviderAOS: (id) => dispatch(approveServiceproviderAOS(id)),
    deleteServiceproviderAOS: (id) => dispatch(deleteServiceproviderAOS(id)),
    filterServiceProvidersAOS: (value) =>
      dispatch(filterServiceProvidersAOS(value)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ServiceProvidersAOS);
