import React, { Component, useState } from 'react';
import myprofile from './../assets/myprofile.png';
import {loginAdmin} from "../Actions/admin/admin";
import {connect} from "react-redux"
import CircularProgress from '@material-ui/core/CircularProgress';
import {authCheck} from "./../auth"
import { useHistory } from "react-router-dom";


const Login = (props) => {
	let history = useHistory();



	React.useEffect(()=>{
		if(authCheck()){
		  history.push("/individuals")
		}
	  },[])


var [email ,setEmail] = useState("");
var [password ,setPassword] = useState("");

const LoginSubmit= (e)=> {
e.preventDefault();

props.loginAdminAction({username: email,password})

}

        return (
            <div>
                <form className="form-horizontal" onSubmit={LoginSubmit}>
                <div class="parent"> 
    <div class="card">
		<div class="card-body">
		  <h3 class="card-title"> Login </h3>
		  <img src={myprofile} width="80px" class="img"/>
		  <form>
				<div class="form-group">
				  <label for="exampleInputEmail1">Email address/Username</label>
				  <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" value = {email} onChange={(e) =>setEmail(e.target.value)}/>
				  <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small>
				</div>
				<div class="form-group">
				  <label for="exampleInputPassword1">Password</label>
				  <input type="password" class="form-control" id="exampleInputPassword1" placeholder="Password" value={password} onChange={(e) =>setPassword(e.target.value)}/>
				</div>
				<div class="form-check">
				  <input type="checkbox" class="form-check-input" id="exampleCheck1" />
				  <label class="form-check-label" for="exampleCheck1">Check me out</label>
				</div>
				
				<button type="submit" class="btn btn-primary">Submit</button>
			  </form>
   
		</div>
      </div>
    </div>

</form>
            </div>
        );
    }


// 
const mapStateToProps = (state) => {
  return { 
    loggedIn: state.adminReducer.loggedIn 
  }
}

const mapDispatchToProps = (dispatch) => {
  return { 
    loginAdminAction: (item)=> dispatch(loginAdmin(item)),

   }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login)
