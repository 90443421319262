export const ADMIN_LOGIN = "ADMIN_LOGIN";
export const CREATE_ADMIN = "CREATE_ADMIN";
export const GET_ALL_COMPANIES = "GET_ALL_COMPANIES";
export const GET_ALL_INDIVIDUALS = "GET_ALL_INDIVIDUALS";
export const GET_ALL_SERVICE_PROVIDERS = "GET_ALL_SERVICE_PROVIDERS";
export const GET_ALL_SERVICE_PROVIDERS_AOS = "GET_ALL_SERVICE_PROVIDERS_AOS";
export const GET_ALL_POSTS = "GET_ALL_POSTS";
export const GET_ALL_REVIEWS = "GET_ALL_REVIEWS";
export const APPROVE_INDIVIDUAL = "APPROVE_INDIVIDUAL";
export const APPROVE_COMPANY = "APPROVE_COMPANY";
export const APPROVE_SERVICE_PROVIDER = "APPROVE_SERVICE_PROVIDER";
export const APPROVE_POST = "APPROVE_POST";
export const APPROVE_REVIEW = "APPROVE_REVIEW";
export const INDIVIDUAL_GET_BY_ID = "INDIVIDUAL_GET_BY_ID";
export const DELETE_SERVICE_PROVIDER = "DELETE_SERVICE_PROVIDER";
export const DELETE_POST = "DELETE_POST";
export const DELETE_REVIEW = "DELETE_REVIEW";
export const SUSPEND_SERVICE_PROVIDER = "SUSPEND_SERVICE_PROVIDER";
export const SUSPEND_SERVICE_SEEKER = "SUSPEND_SERVICE_SEEKER";
export const APPROVE_AOS = "APPROVE_AOS";
export const DELETE_AOS = "DELETE_AOS";
