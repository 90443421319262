import React, { Component } from 'react';
import axios from 'axios';
import Header from './Header';
import {Card,CardActions,CardMedia,CardActionArea,CardContent,Typography,Button} from '@material-ui/core'
import profile from '../../src/assets/profile.jpg';
import ScreenWrapper from '../ScreenWrapper';
import HeaderAndDrawer from './Header';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';


const Sellers = ()=> {


  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

// constructor(){
//     super();
//     this.state = {
//         users: []
//     }
// }


// componentDidMount(){
//     axios.get('https://sbay-mrz.herokuapp.com/sellers/getsellers')
//       .then(res => {
//         const users = res.data;
//         console.log("users are : ",users)
//         this.setState({ users });
//       })
// }

// deleteSeller(id){
// axios.delete(`https://sbay-mrz.herokuapp.com/sellers/${id}`)
// .then(res=>{
//     console.log("res",res);
// })
// }


        return (
            <div>
              <ScreenWrapper>
                service seeker
  {/* <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
    <Tab label="Item One"  />
    <Tab label="Item Two"  />
    <Tab label="Item Three"  />
  </Tabs>
<TabPanel value={value} index={0}>
  Item One
</TabPanel>
<TabPanel value={value} index={1}>
  Item Two
</TabPanel>
<TabPanel value={value} index={2}>
  Item Three
</TabPanel> */}
              </ScreenWrapper>
            </div>
        );
    }


export default Sellers;