import React, { Component, useEffect,useState } from 'react';
import axios from 'axios';
import Header from './Header';
import {Card,CardActions,CardMedia,CardActionArea,CardContent,Typography,Button} from '@material-ui/core'
import profile from '../../src/assets/profile.jpg';
import ScreenWrapper from '../ScreenWrapper';
import HeaderAndDrawer from './Header';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import {getAllCompanies,approveCompany} from "../Actions/admin/admin";
import {connect} from "react-redux"
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useHistory } from "react-router-dom";
import {authCheck} from "./../auth";



const useSortableData = (items, config = null) => {
  const [sortConfig, setSortConfig] = React.useState(config);

  const sortedItems = React.useMemo(() => {
    let sortableItems = [...items];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [items, sortConfig]);

  const requestSort = (key) => {
    let direction = 'ascending';
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === 'ascending'
    ) {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  return { items: sortedItems, requestSort, sortConfig };
};



const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
  });

const Companies = (props)=> {
  const [textcheck, settextcheck] = useState("Fatching Data....");



  const { items, requestSort, sortConfig } = useSortableData(props.companies);

  useEffect(() => {
    const timer = setTimeout(() => {
      settextcheck("No Data Found")
    }, 6000);
    return () => clearTimeout(timer);
  }, []);
  const getClassNamesFor = (name) => {
    
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };


    const classes = useStyles();
    let history = useHistory();


    useEffect(()=>{

      if(!authCheck()){
        history.push("/")
        window.location.reload();

      }else{

    props.fetchCompanies();

      }

    },[])


    
    async function approveUser(selectedRow) {
      await props.approveCompanyUser(selectedRow._id,selectedRow);    
    }

        return (
            <div>
              <ScreenWrapper>
                
                <h2 style={{textAlign: 'center'}}> Companies </h2>


                <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead className="tablehead">
          <TableRow>
            <TableCell>Name

            <button
              type="button"
              onClick={() => requestSort('name')}
              className={getClassNamesFor('name')}
            > 
              sort
             </button>
            </TableCell>
            <TableCell align="right">Email
            
            
            <button
              type="button"
              onClick={() => requestSort('email')}
              className={getClassNamesFor('email')}
            > 
            sort
             </button>
            </TableCell>
            <TableCell align="right">MEMBER ID
            
            <button
              type="button"
              onClick={() => requestSort('member_id')}
              className={getClassNamesFor('member_id')}
            > 
            sort
             </button>
            </TableCell>
            <TableCell align="right">ACTIVE
         
             </TableCell>
            <TableCell align="right">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {items && items.length === 0 ? 
          <TableRow>
          <TableCell colSpan={5} align="center"><h6>{textcheck}</h6></TableCell>
          </TableRow>
        : 
        <>
        {items?.map((row) => (
            <TableRow key={row._id}>
              <TableCell component="th" scope="row">
              <Button color="primary" className="namebtn" onClick={()=>
                history.push({
                  pathname: '/profile',
                  state: row,
                })
                }> {row.name} </Button> 
              </TableCell>
              <TableCell align="right">{row.email}</TableCell>
              <TableCell align="right">{row.member_id}</TableCell>
              <TableCell align="right">{row.active ? "true" : "false"}</TableCell>
              <TableCell align="right"> <Button variant="outlined" color="primary" onClick={()=>approveUser(row)} disabled={row.approved}> Approve </Button> </TableCell>
            </TableRow>
          ))}
        </>
        }
         
        </TableBody>
      </Table>
    </TableContainer>

              </ScreenWrapper>
            </div>
        );
    }


const mapStateToProps = (state) => {
  return { 
    companies: state.adminReducer.companies 
  }
}

const mapDispatchToProps = (dispatch) => {
  return { 
    fetchCompanies: ()=> dispatch(getAllCompanies()),
    approveCompanyUser: (id,selectedRow)=> dispatch(approveCompany(id,selectedRow)),

   }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Companies)