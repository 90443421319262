import React, { useEffect } from 'react';
import HeaderAndDrawer from './Components/Header';



const ScreenWrapper = (props) => {

	return (
		<div className="col-10 w-80 ml-20 main-table-screen" id="main" style={{marginTop: 100,marginLeft: 230}}>
			<div className="screen-wrapper-children">{props.children}</div>
		</div>
	
	);
};

export default ScreenWrapper;
