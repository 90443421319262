import React, { Component, useEffect, useState, useCallback } from "react";
import axios from "axios";
import Header from "./Header";
import {
  Card,
  CardActions,
  CardMedia,
  CardActionArea,
  CardContent,
  Typography,
  Button,
} from "@material-ui/core";
import debounce from "lodash.debounce";
import moment from "moment";
import profile from "../../src/assets/profile.jpg";
import ScreenWrapper from "../ScreenWrapper";
import Grid from "@material-ui/core/Grid";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import {
  getAllPosts,
  approvePost,
  deletePost,
  filterPosts,
} from "../Actions/admin/admin";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { useHistory } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { authCheck } from "./../auth";
import SearchBar from "./search";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});


const useSortableData = (items, config = null) => {
  const [sortConfig, setSortConfig] = React.useState(config);

  const sortedItems = React.useMemo(() => {
    let sortableItems = [...items];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [items, sortConfig]);

  const requestSort = (key) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  return { items: sortedItems, requestSort, sortConfig };
};



const Posts = (props) => {
  const classes = useStyles();
  const [selectedRow, setSelectedRow] = useState("");
  const [textcheck, settextcheck] = useState("Fatching Data....");
  const [open, setOpen] = React.useState(false);
  const { items, requestSort, sortConfig } = useSortableData(
    props?.posts?.data ? props?.posts?.data : []
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      settextcheck("No Data Found")
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  const handleClickOpen = (row) => {
    setOpen(true);
    setSelectedRow(row);
  };

  const deletePost = async (id) => {
    await props.deletePostAction(id);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let history = useHistory();

  useEffect(() => {
    if (!authCheck()) {
      history.push("/");
      window.location.reload();
    } else {
      props.fetchServicePosts();
    }
  }, [props]);

  async function approvePost(selectedRow) {
    await props.approveSeriveProviderPost(selectedRow._id);
  }

  const debounceFn = useCallback(
    debounce((_searchVal) => {
      props.filterPosts(_searchVal);
      // send the server request here
    }, 1000),
    []
  );

  async function onChange(event) {
    event.persist();
    console.log("event.target.value ==== ", event?.target?.value);
    debounceFn(event.target.value);
    // await props.filterPosts(value);
  }



  return (
    <div>
      <Dialog
        open={open}
        fullWidth
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Post Details"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Grid item xs={12} lg={12}>
              <p>
                {" "}
                <span> Post ID : </span> {selectedRow._id}{" "}
              </p>
              <p>
                {" "}
                <span> Reference ID : </span> {selectedRow.reference_id}{" "}
              </p>
              <p>
                {" "}
                <span> Assignment Area : </span> {selectedRow.assignment_area}{" "}
              </p>

              {selectedRow.proposal_timeline && (
                <p>
                  {" "}
                  <span> Proposal Timeline : </span>{" "}
                  {moment(selectedRow.proposal_timeline).format(
                    "YYYY-MM-DD HH:MM"
                  )}{" "}
                </p>
              )}
              {selectedRow.completion_timeline && (
                <p>
                  {" "}
                  <span> Completion Timeline : </span>{" "}
                  {moment(selectedRow.completion_timeline).format(
                    "YYYY-MM-DD HH:MM"
                  )}{" "}
                </p>
              )}
              {selectedRow.budget && (
                <p>
                  {" "}
                  <span> Budget : </span> {selectedRow.budget}{" "}
                </p>
              )}
              {selectedRow.user_id && (
                <p>
                  {" "}
                  <span> User ID : </span> {selectedRow.user_id}{" "}
                </p>
              )}
              {selectedRow.user_type && (
                <p>
                  {" "}
                  <span> User Type : </span> {selectedRow.user_type}{" "}
                </p>
              )}
              {selectedRow.details && (
                <p>
                  {" "}
                  <span> Details : </span> {selectedRow.details}{" "}
                </p>
              )}
              {selectedRow.other_information && (
                <p>
                  {" "}
                  <span> Other Information : </span>{" "}
                  {selectedRow.other_information}{" "}
                </p>
              )}
              <p>
                {" "}
                <span> Approved : </span>{" "}
                {selectedRow.approved ? "true" : "false"}
              </p>

              <p>
                {" "}
                <span> Deleted : </span>{" "}
                {selectedRow.deleted ? "true" : "false"}
              </p>

              <p>
                {" "}
                <span> Active: </span> {selectedRow.active ? "true" : "false"}
              </p>

              {selectedRow.created_at && (
                <p>
                  {" "}
                  <span> Created At: </span>{" "}
                  {moment(selectedRow.created_at).format("YYYY-MM-DD HH:MM")}
                </p>
              )}
              <ButtonGroup
                variant="text"
                color="primary"
                aria-label="text primary button group"
              >
                {/* <Button onClick={() => suspendUser(user._id)}>Suspend</Button> */}
              </ButtonGroup>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          {!selectedRow.approved && !selectedRow.deleted ? (
            <Button onClick={() => approvePost(selectedRow)}>Approve</Button>
          ) : null}
        </DialogActions>
      </Dialog>
      <ScreenWrapper>
        <h2 style={{ textAlign: "center" }}> Posts </h2>
        <SearchBar onChange={onChange} />
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead className="tablehead">
              <TableRow>
                <TableCell>Reference ID</TableCell>
                <TableCell align="right">Assignment Area
                <button
                    type="button"
                    onClick={() => requestSort("assignment_area")}
                    className={getClassNamesFor("assignment_area")}
                  >
                    sort
                  </button>
                
                </TableCell>
                <TableCell align="right">Proposal Timeline
                
                <button
                    type="button"
                    onClick={() => requestSort("proposal_timeline")}
                    className={getClassNamesFor("proposal_timeline")}
                  >
                    sort
                  </button>
                </TableCell>
                <TableCell align="right">Completion Timeline
                
                <button
                    type="button"
                    onClick={() => requestSort("completion_timeline")}
                    className={getClassNamesFor("completion_timeline")}
                  >
                    sort
                  </button>
                </TableCell>
                <TableCell align="right">User Type 
                
                <button
                    type="button"
                    onClick={() => requestSort("user_type")}
                    className={getClassNamesFor("user_type")}
                  >
                    sort
                  </button>
                </TableCell>
                <TableCell align="right">Budget 
                
                <button
                    type="button"
                    onClick={() => requestSort("budget")}
                    className={getClassNamesFor("budget")}
                  >
                    sort
                  </button>
                </TableCell>
                <TableCell align="right">Deleted </TableCell>
                <TableCell align="right">Approved </TableCell>
                <TableCell align="right">Actions </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {items && items.length === 0 ? 
          <TableRow>
          <TableCell colSpan={8} align="center"><h6>{textcheck}</h6></TableCell>
          </TableRow>
        : 
        <>
        {items.map((row) => (
                  <TableRow key={row._id}>
                    <TableCell component="th" scope="row">
                      <Button
                        color="primary"
                        className="namebtn"
                        onClick={() => handleClickOpen(row)}
                      >
                        {" "}
                        {row.reference_id}{" "}
                      </Button>
                    </TableCell>
                    <TableCell align="right">{row.assignment_area}</TableCell>
                    <TableCell align="right">
                      {moment(row.proposal_timeline).format("YYYY-MM-DD HH:MM")}
                    </TableCell>
                    <TableCell align="right">
                      {moment(row.completion_timeline).format(
                        "YYYY-MM-DD HH:MM"
                      )}
                    </TableCell>
                    <TableCell align="right">{row.user_type}</TableCell>
                    <TableCell align="right">{row.budget}</TableCell>
                    <TableCell align="right">
                      {row.deleted ? "true" : "false"}
                    </TableCell>
                    <TableCell align="right">
                      {row.approved ? "true" : "false"}
                    </TableCell>
                    <TableCell align="right">
                      {" "}
                      <Button
                        variant="outlined"
                        color="primary"
                        disabled={row.approved || row.deleted}
                        onClick={() => approvePost(row)}
                      >
                        {" "}
                        Approve{" "}
                      </Button>{" "}
                    </TableCell>
                  </TableRow>
                ))}
        </>
        }
            </TableBody>
          </Table>
        </TableContainer>
      </ScreenWrapper>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    posts: state.adminReducer.posts,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchServicePosts: () => dispatch(getAllPosts()),
    approveSeriveProviderPost: (id) => dispatch(approvePost(id)),
    deletePostAction: (id) => dispatch(deletePost(id)),
    filterPosts: (value) => dispatch(filterPosts(value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Posts);
