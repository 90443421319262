var authenticated = false;

export const authCheck = () => {
  const user = localStorage.getItem("admin");
  if(user !== null){
      let userParsed = JSON.parse(localStorage.getItem("admin"));
      if(userParsed){
    authenticated = true
      }
  }
  return authenticated
}