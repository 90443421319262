import {
  CREATE_ADMIN,
  GET_ALL_COMPANIES,
  GET_ALL_INDIVIDUALS,
  GET_ALL_SERVICE_PROVIDERS,
  GET_ALL_POSTS,
  INDIVIDUAL_GET_BY_ID,
  GET_ALL_REVIEWS,
  APPROVE_COMPANY,
  APPROVE_INDIVIDUAL,
  APPROVE_SERVICE_PROVIDER,
  SUSPEND_SERVICE_PROVIDER,
  SUSPEND_SERVICE_SEEKER,
  GET_ALL_SERVICE_PROVIDERS_AOS,
  APPROVE_AOS,
  DELETE_AOS,
} from "../Constants/constants";

const initialState = {
  companies: [],
  individuals: [],
  serviceProviders: [],
  posts: [],
  reviews: [],
  serviceProvidersAOS: [],
  user: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CREATE_ADMIN:
      return {
        ...state,
        creationData: action.payload,
      };

    case GET_ALL_POSTS:
      return {
        ...state,
        posts: action.payload,
      };

    case GET_ALL_REVIEWS:
      return {
        ...state,
        reviews: action.payload,
      };

    case INDIVIDUAL_GET_BY_ID:
      return {
        ...state,
        user: action.payload,
      };

    case CREATE_ADMIN:
      return {
        ...state,
        creationData: action.payload,
      };

    case GET_ALL_COMPANIES:
      return {
        ...state,
        companies: action.payload.data,
      };

    case GET_ALL_INDIVIDUALS:
      return {
        ...state,
        individuals: action.payload.data,
      };

    case GET_ALL_SERVICE_PROVIDERS:
      return {
        ...state,
        serviceProviders: action.payload.data,
      };

    case GET_ALL_SERVICE_PROVIDERS_AOS:
      return {
        ...state,
        serviceProvidersAOS: action.payload.data,
      };

    case INDIVIDUAL_GET_BY_ID:
      return {
        ...state,
        user: action.payload,
      };

    case APPROVE_INDIVIDUAL:
      return {
        ...state,
        individuals: state.individuals.map((item) => {
          if (item._id === action.payload.id) {
            item["approved"] = true;
          }
          return item;
        }),
      };

    case APPROVE_AOS:
      console.log("actions in approve === ", state.serviceProvidersAOS);
      return {
        ...state,
        serviceProvidersAOS: state.serviceProvidersAOS.map((item) => {
          if (item._id === action.payload.id) {
            item["approved"] = true;
          }
          return item;
        }),
      };

    case DELETE_AOS:
      console.log("actions in delete === ", state.serviceProvidersAOS);
      return {
        ...state,
        serviceProvidersAOS: state.serviceProvidersAOS.filter((item) => {
          if (item._id !== action.payload.id) {
            return item;
          }
        }),
      };

    case APPROVE_COMPANY:
      return {
        ...state,
        companies: state.companies.map((item) => {
          if (item._id === action.payload.id) {
            item["approved"] = true;
          }
          return item;
        }),
      };

    case APPROVE_SERVICE_PROVIDER:
      return {
        ...state,
        serviceProviders: state.serviceProviders.map((item) => {
          if (item._id === action.payload.id) {
            item["approved"] = true;
          }
          return item;
        }),
      };

    case SUSPEND_SERVICE_SEEKER:
      return {
        ...state,
        individuals: state.individuals.map((item) => {
          if (item._id === action.payload.id) {
            item["suspended"] = true;
          }
          return item;
        }),
      };

    case SUSPEND_SERVICE_PROVIDER:
      return {
        ...state,
        status: action.payload,
      };

    default:
      return state;
  }
}
