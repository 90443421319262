import React, { Component, useEffect, useCallback,useState} from "react";
import {
  Card,
  CardActions,
  CardMedia,
  CardActionArea,
  CardContent,
  Typography,
  Button,
} from "@material-ui/core";
import ScreenWrapper from "../ScreenWrapper";
import {
  approveIndividual,
  getAllIndividuals,
  filterIndividuals,
} from "../Actions/admin/admin";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { useHistory } from "react-router-dom";
import { authCheck } from "./../auth";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import SearchBar from "./search";
import debounce from "lodash.debounce";
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const useSortableData = (items, config = null) => {
  const [sortConfig, setSortConfig] = React.useState(config);

  const sortedItems = React.useMemo(() => {
    let sortableItems = [...items];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [items, sortConfig]);

  const requestSort = (key) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  return { items: sortedItems, requestSort, sortConfig };
};

const Individuals = (props) => {
  const [textcheck, settextcheck] = useState("Fatching Data....");

  const { items, requestSort, sortConfig } = useSortableData(
    props.individuals ? props.individuals : []
  );
  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      settextcheck("No Data Found")
    }, 6000);
    return () => clearTimeout(timer);
  }, []);

  let history = useHistory();

  const classes = useStyles();

  useEffect(() => {
    if (!authCheck()) {
      history.push("/");
      window.location.reload();
    } else {
      props.fetchIndividuals();
    }
  }, []);

  async function approveUser(selectedRow, actiontype) {
    await props.approveIndividualUser(selectedRow._id, selectedRow, actiontype);
  }

  const debounceFn = useCallback(
    debounce((_searchVal) => {
      props.filterIndividuals(_searchVal);
      // send the server request here
    }, 1000),
    []
  );

  async function onChange(event) {
    event.persist();
    console.log("event.target.value ==== ", event?.target?.value);
    debounceFn(event.target.value);
    // await props.filterPosts(value);
  }

  return (
    <div>
      <ScreenWrapper>
        <h2 style={{ textAlign: "center" }}> Individuals </h2>
        <SearchBar onChange={onChange} />
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead className="tablehead">
              <TableRow>
                <TableCell>
                 
                  Name

                  <button
                 
                    type="button"
                    onClick={() => requestSort("name")}
                    className={getClassNamesFor("name")}
                  >
                    sort
                  </button>
                </TableCell>
                <TableCell align="right">
                  Email
                  <button
                    type="button"
                    onClick={() => requestSort("email")}
                    className={getClassNamesFor("email")}
                  >
                    sort
                  </button>
                </TableCell>
                <TableCell align="right">
                  Designation
                  <button
                    type="button"
                    onClick={() => requestSort("designation")}
                    className={getClassNamesFor("designation")}
                  >
                    sort
                  </button>
                </TableCell>
                <TableCell align="right">
                  Organization
                  <button
                    type="button"
                    onClick={() => requestSort("organisation")}
                    className={getClassNamesFor("organisation")}
                  >
                    sort
                  </button>
                </TableCell>
                <TableCell align="right">Actions </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {items && items.length === 0 ? 
              <TableRow>
              <TableCell colSpan={5} align="center"><h6>{textcheck}</h6></TableCell>
              </TableRow>
             : 
             <> 
             {items.map((row) => (
                <TableRow key={row._id}>
                  <TableCell component="th" scope="row">
                    <Button
                      color="primary"
                      className="namebtn"
                      onClick={() =>
                        history.push({
                          pathname: "/profile",
                          state: row,
                        })
                      }
                    >
                      {" "}
                      {row.name}{" "}
                    </Button>
                  </TableCell>
                  <TableCell align="right">{row.email}</TableCell>
                  <TableCell align="right">{row.designation}</TableCell>
                  <TableCell align="right">{row.organisation}</TableCell>
                  <TableCell align="right">
                    <Button
                      variant="outlined"
                      color="primary"
                      disabled={row.approved}
                      onClick={() => approveUser(row, "approved")}
                    >
                      {" "}
                      Approve{" "}
                    </Button>

                    {/* {row.suspended ? 
                <Button onClick={()=>approveUser(row,"approved")} > Approve </Button> 
                :
                 <Button onClick={()=>approveUser(row,"suspend")} > Suspend </Button> 
                }  */}
                  </TableCell>
                </TableRow>
              ))}
             </>
             }
              
            </TableBody>
          </Table>
        </TableContainer>
      </ScreenWrapper>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    individuals: state.adminReducer.individuals,
    adminReducer: state.adminReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchIndividuals: () => dispatch(getAllIndividuals()),
    approveIndividualUser: (id, selectedRow, type) =>
      dispatch(approveIndividual(id, selectedRow, type)),
    filterIndividuals: (value) => dispatch(filterIndividuals(value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Individuals);
