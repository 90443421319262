import React, { Component, useEffect, useState } from "react";
import {
  Card,
  CardActions,
  CardMedia,
  CardActionArea,
  CardContent,
  Typography,
  Button,
} from "@material-ui/core";
import moment from "moment";
import ScreenWrapper from "../ScreenWrapper";
import {
  deleteServiceProvider,
  getAllIndividualById,
  suspendServiceProvider,
  activeServiceProvider,
} from "../Actions/admin/admin";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const Profile = (props) => {
  const classes = useStyles();
  const [user, setUser] = useState({});

  useEffect(() => {
    if (props && props.location.state) {
      let data = props.location.state;
      setUser(data);
    }
  }, [user]);

  const history = useHistory();

  const routeChange = (props) =>{ 
    if(props.company_type === "Company(M)" || props.company_type == "Company/Org(WM)"){
      let path = `/companies`; 
      history.push(path);
    }
    else if (props.member_dropdown === "Individual(M)" || props.member_dropdown === "Non-Individual"){
      let path = `/individuals`; 
      history.push(path);
    }
    else {
      let path = `/service-provider`; 
      history.push(path);
    }
  }

  const deleteUser = async (id) => {
    await props.deleteUserAction(id);
  };

     const suspendUser = async (id, user) => {
    await props.suspendUserAction(id, props.location.state);
    setTimeout(() => {
      routeChange(props.location.state)  
    }, 1000);
  };

  const activeUser = async (id, user) => {
    await props.activeUserAction(id, props.location.state);
    setTimeout(() => {
      routeChange(props.location.state)
    }, 1000);
    
  };


  console.log("props",props.location.state);

  return (
    <div>
      <ScreenWrapper>
        <h3 className="profile-heading"> Details </h3>
        {user && (
        <Grid container spacing={1}>
          <Grid item xs={12} lg={12} className="user-profile">
            <Paper style={{ padding: 50 }}>
            {user?.area_of_specialization ? 
          <Grid item xs={6} lg={6} className="leftsidegrid">
          <h6 className="h6adminaoe">Area Of Specialization</h6>
          {user?.area_of_specialization && user?.area_of_specialization.map((row) => (   
          
            <ul className="ulliadmin">
              <li>{row}</li>
            </ul>
            ))}
          </Grid>
          :
          "" }
              <p>
                {" "}
                <span> Name : </span> {user.name}{" "}
              </p>
              <p>
                {" "}
                <span> email : </span> {user.email}{" "}
              </p>
              {user.created_at && (
                <p>
                  {" "}
                  <span> Created At : </span> {user.created_at}{" "}
                </p>
              )}

{user.member_dropdown && (
                <p>
                  {" "}
                  <span>  Member Type: </span> {user.member_dropdown}{" "}
                </p>
              )}

{user.cnic && (
                <p>
                  {" "}
                  <span> Cnic : </span> {user.cnic}{" "}
                </p>
              )}


{user.organisation && (
                <p>
                  {" "}
                  <span> Organisation : </span> {user.organisation}{" "}
                </p>
              )}


           
              {user.cinc && (
                <p>
                  {" "}
                  <span> Cnic : </span> {user.cnic}{" "}
                </p>
              )}
              {user.organisation && (
                <p>
                  {" "}
                  <span> organisation : </span> {user.organisation}{" "}
                </p>
              )}
              {user.designation && (
                <p>
                  {" "}
                  <span> Designation : </span> {user.designation}{" "}
                </p>
              )}
              {user.member_since && (
                <p>
                  {" "}
                  <span> member_since : </span>{" "}
                  {moment(user.member_since).format("YYYY-MM-DD HH:MM")}{" "}
                </p>
              )}
              {user.member_type && (
                <p>
                  {" "}
                  <span> member type : </span> {user.member_type}{" "}
                </p>
              )}
    
              
                <p>
                  {" "}
                  <span> Approved: </span> {user.approved ? "true" : "false"}
                </p>
              
              
                <p>
                  {" "}
                  <span> Suspended: </span> {user.suspended ? "true" : "false"}
                </p>
              
              {user.contact_number1 && (
                <p>
                  {" "}
                  <span> Contact(one): </span> {user.contact_number1}
                </p>
              )}
              {user.contact_number2 && (
                <p>
                  {" "}
                  <span> Contact(two): </span>
                  {user.contact_number2}
                </p>
              )}
              {user.incorporation_number && (
                <p>
                  {" "}
                  <span> Incorporation Number: </span>{" "}
                  {user.incorporation_number}
                </p>
              )}

{user.loc_of_offices_pk && (
                <p>
                  {" "}
                  <span> Location Of Offices(PK): </span>{" "}
                  {user.loc_of_offices_pk}
                </p>
              )}



              {user.ntn_strn && (
                <p>
                  {" "}
                  <span> NTN/STRN: </span>
                  {user.ntn_strn}
                </p>
              )}
              
                <p>
                  {" "}
                  <span> Active: </span> {user.active ? "true" : "false"}
                </p>

                <p>
                {" "}
                <span> website : </span> {user.website}{" "}
              </p>
              <p>
                {" "}
                <span> Promo code : </span> {user.promo_code}{" "}
              </p>
              
              <ButtonGroup
                variant="text"
                color="primary"
                aria-label="text primary button group"
              >
              {user.suspended ? <Button
                  onClick={() => activeUser(user._id)}
                >
                  Active
                </Button> 
                : <Button
                  onClick={() => activeUser(user._id)}
                  disabled
                >
                  Active
                </Button>
                }
                
                <Button
                  onClick={() => suspendUser(user._id)}
                  disabled={user.suspended}
                >
                  Suspend
                </Button>
                {/* <Button onClick={() => deleteUser(user._id)}>Delete</Button> */}
              </ButtonGroup>
            </Paper>
          </Grid>
         </Grid>
        )}
      </ScreenWrapper>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.adminReducer.user,
    adminReducer: state.adminReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUser: (id) => dispatch(getAllIndividualById(id)),
    deleteUserAction: (id) => dispatch(deleteServiceProvider(id)),
    suspendUserAction: (id, user) => dispatch(suspendServiceProvider(id, user)),
    activeUserAction: (id, user) => dispatch(activeServiceProvider(id, user)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
