import React, { Component, useEffect, useState, useCallback } from "react";
import axios from "axios";
import Header from "./Header";
import {
  Card,
  CardActions,
  CardMedia,
  CardActionArea,
  CardContent,
  Typography,
  Button,
} from "@material-ui/core";
import profile from "../../src/assets/profile.jpg";
import ScreenWrapper from "../ScreenWrapper";
import Grid from "@material-ui/core/Grid";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import {
  getAllReviews,
  approveReview,
  deleteReview,
  filterReviews,
} from "../Actions/admin/admin";
import moment from "moment";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { useHistory } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { authCheck } from "./../auth";
import SearchBar from "./search";
import debounce from "lodash.debounce";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  paper: { minWidth: "500px" },
});


const useSortableData = (items, config = null) => {
  const [sortConfig, setSortConfig] = React.useState(config);
  const sortedItems = React.useMemo(() => {
    let sortableItems = [...items];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [items, sortConfig]);

  const requestSort = (key) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  return { items: sortedItems, requestSort, sortConfig };
};

const Reviews = (props) => {
  const classes = useStyles();
  const [textcheck, settextcheck] = useState("Fatching Data....");
  const [selectedRow, setSelectedRow] = useState("");

  const [open, setOpen] = React.useState(false);

  const { items, requestSort, sortConfig } = useSortableData(
   
                props?.reviews?.data ?
                props.reviews.data : []
  );
  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      settextcheck("No Data Found")
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  const handleClickOpen = (row) => {
    // console.log(row);
    setOpen(true);
    setSelectedRow(row);
  };

  const deleteReview = async (id) => {
    await props.deleteReviewAction(id);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let history = useHistory();

  useEffect(() => {
    if (!authCheck()) {
      history.push("/");
      window.location.reload();
    } else {
      props.fetchServiceReviews();
    }
  }, [props]);

  async function approveReview(selectedRow) {
    // console.log(selectedRow,"asjkdasjkdhkjasdhkjashdkasjhdjaskd");
    await props.approveSeriveProviderReview(selectedRow._id);
  }

  const debounceFn = useCallback(
    debounce((_searchVal) => {
      props.filterReviews(_searchVal);
      // send the server request here
    }, 1000),
    []
  );

  async function onChange(event) {
    event.persist();
    // console.log("event.target.value ==== ", event?.target?.value);
    debounceFn(event.target.value);
    // await props.filterPosts(value);
  }

  return (
    <div>
      <Dialog
        open={open}
        fullWidth
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Review Details"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Grid item xs={12} lg={12}>
              {/* <p>
                {" "}
                <span> Review ID : </span> {selectedRow._id}{" "}
              </p>
              {selectedRow.user_id && (
                <p>
                  {" "}
                  <span> User ID : </span> {selectedRow.user_id}{" "}
                </p>
              )} */}

              {selectedRow.post_id && (
                <p>
                  {" "}
                  <span> Post ID : </span> {selectedRow.post_id}{" "}
                </p>
              )}
              {selectedRow.service_provider && (
                <p>
                  {" "}
                  <span> Service Provider Name : </span>{" "}
                  {selectedRow.service_provider.name}{" "}
                </p>
              )}
              {selectedRow.service_provider && (
                <p>
                  {" "}
                  <span> Service Provider Email : </span>{" "}
                  {selectedRow.service_provider.email}{" "}
                </p>
              )}
              {selectedRow.service_provider && (
                <p>
                  {" "}
                  <span> Service Provider Username : </span>{" "}
                  {selectedRow.service_provider.username}{" "}
                </p>
              )}



              {JSON.stringify(selectedRow.rating) && (
                <p>
                  {" "}
                  <span> Rating : </span> {JSON.stringify(selectedRow.rating)}{" "}
                </p>
              )}

              {selectedRow.heading && (
                <p>
                  {" "}
                  <span> Heading : </span> {selectedRow.heading}{" "}
                </p>
              )}

              {selectedRow.content && (
                <p>
                  {" "}
                  <span> Content : </span> {selectedRow.content}{" "}
                </p>
              )}

              <p>
                {" "}
                <span> Approved : </span>{" "}
                {selectedRow.approved ? "true" : "false"}
              </p>

              <p>
                {" "}
                <span> Deleted : </span>{" "}
                {selectedRow.deleted ? "true" : "false"}
              </p>

              {selectedRow.created_at && (
                <p>
                  {" "}
                  <span> Created At: </span>{" "}
                  {moment(selectedRow.created_at).format("YYYY-MM-DD HH:MM")}
                </p>
              )}
              <ButtonGroup
                variant="text"
                color="primary"
                aria-label="text primary button group"
              >
                {/* <Button onClick={() => suspendUser(user._id)}>Suspend</Button> */}
              </ButtonGroup>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          {/* {!selectedRow.deleted ? (
            <Button onClick={() => deleteReview(selectedRow._id)}>
              Delete
            </Button>
          ) : null} */}
          {!selectedRow.approved && !selectedRow.deleted ? (
            <Button
              variant="outlined"
              color="primary"
              onClick={() => approveReview(selectedRow)}
            >
              Approve
            </Button>
          ) : null}
        </DialogActions>
      </Dialog>
      <ScreenWrapper>
        <h2 style={{ textAlign: "center" }}> Reviews </h2>
        <SearchBar onChange={onChange} />
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead className="tablehead">
              <TableRow>
                {/* <TableCell>Review ID

                <button
                    type="button"
                    onClick={() => requestSort("_id")}
                    className={getClassNamesFor("_id")}
                  >
                    sort
                  </button>

                </TableCell> */}
                {/* <TableCell align="right">User ID
                <button
                    type="button"
                    onClick={() => requestSort("user_id")}
                    className={getClassNamesFor("user_id")}
                  >
                    sort
                  </button>
                
                </TableCell> */}
                <TableCell align="right">Post ID
                
                <button
                    type="button"
                    onClick={() => requestSort("post_id")}
                    className={getClassNamesFor("post_id")}
                  >
                    sort
                  </button>
                </TableCell>
                <TableCell align="right">Heading
                <button
                    type="button"
                    onClick={() => requestSort("heading")}
                    className={getClassNamesFor("heading")}
                  >
                    sort
                  </button></TableCell>
                <TableCell align="right">Deleted </TableCell>
                <TableCell align="right">Approved </TableCell>
                <TableCell align="right">Actions </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {items && items.length === 0 ? 
              <TableRow>
              <TableCell colSpan={7} align="center"><h6>{textcheck}</h6></TableCell>
              </TableRow>
             : 
             <>
             {items.map((row) => (
                  <TableRow key={row._id}>
                    {/* <TableCell component="th" scope="row">
                      <Button
                        color="primary"
                        className="namebtn"
                        onClick={() => handleClickOpen(row)}
                      >
                        {" "}
                        {row._id}{" "}
                      </Button>
                    </TableCell>
                    <TableCell align="right">{row.user_id}</TableCell> */}
                    <TableCell align="right">
                    <Button
                        color="primary"
                        className="namebtn"
                        onClick={() => handleClickOpen(row)}
                      >
                        {" "}
                        {row.post_id}{" "}
                      </Button></TableCell>
                    <TableCell align="right">{row.heading}</TableCell>
                    <TableCell align="right">
                      {row.deleted ? "true" : "false"}
                    </TableCell>
                    <TableCell align="right">
                      {row.approved ? "true" : "false"}
                    </TableCell>
                    <TableCell align="right">
                      {" "}
                      <Button
                        variant="outlined"
                        color="primary"
                        disabled={row.approved || row.deleted}
                        onClick={() => approveReview(row)}
                      >
                        {" "}
                        Approve{" "}
                      </Button>{" "}
                    </TableCell>
                  </TableRow>
                ))}
             </>
             }
            </TableBody>
          </Table>
        </TableContainer>
      </ScreenWrapper>
    </div>
  );
};

const mapStateToProps = (state) => {
  console.log("state.adminReducer.reviews ==== ", state.adminReducer.reviews);
  return {
    reviews: state.adminReducer.reviews,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchServiceReviews: () => dispatch(getAllReviews()),
    approveSeriveProviderReview: (id) => dispatch(approveReview(id)),
    deleteReviewAction: (id) => dispatch(deleteReview(id)),
    filterReviews: (value) => dispatch(filterReviews(value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Reviews);
