import React from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import { Link } from "react-router-dom";
import DescriptionIcon from "@material-ui/icons/Description";
import Button from "@material-ui/core/Button";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import RateReviewIcon from "@material-ui/icons/RateReview";
import PostAddIcon from "@material-ui/icons/PostAdd";
import logo1 from "../assets/logo1.png";
import "./custom.css";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

export default function HeaderAndDrawer(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Apex Dashboard
          </Typography>
          <Button
            className="logoutbtn"
            color="inherit"
            onClick={() => {
              localStorage.clear();
              window.location.reload();
            }}
          >
            Logout
          </Button>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <img src={logo1} width="150px" class="img" />
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          {["Individuals"].map((text, index) => (
            <Link to="/individuals" style={{ textDecoration: "none" }}>
            <ListItem button key={text}>
                <ListItemIcon>
                  {index % 2 === 0 ? <DescriptionIcon /> : <DescriptionIcon />}
                </ListItemIcon>
                <ListItemText className="textcolor" primary={text} />
            </ListItem>
            </Link>
          ))}
          {["Companies"].map((text, index) => (
            <Link to="/companies" style={{ textDecoration: "none" }}>
            <ListItem button key={text}>
              
                <ListItemIcon>
                  {index % 2 === 0 ? <DescriptionIcon /> : <DescriptionIcon />}
                </ListItemIcon>
                <ListItemText className="textcolor" primary={text} />
              
            </ListItem>
            </Link>
          ))}
          {["Service Provider"].map((text, index) => (
            <Link to="/service-provider" style={{ textDecoration: "none" }}>
            <ListItem button key={text}>
                <ListItemIcon>
                  {index % 2 === 0 ? <DescriptionIcon /> : <DescriptionIcon />}
                </ListItemIcon>
                <ListItemText className="textcolor" primary={text} />
            </ListItem>
            </Link>
          ))}
          {["AOS"].map((text, index) => (
            <Link
                to="/service-provider-aos"
                style={{ textDecoration: "none" }}
              >
            <ListItem button key={text}>
                <ListItemIcon>
                  {index % 2 === 0 ? <DescriptionIcon /> : <DescriptionIcon />}
                </ListItemIcon>
                <ListItemText className="textcolor" primary={text} />
            </ListItem>
            </Link>
          ))}
          {["Posts"].map((text, index) => (
            <Link to="/posts" style={{ textDecoration: "none" }}>
            <ListItem button key={text}>
                <ListItemIcon>
                  {index % 1 === 0 ? <PostAddIcon /> : <PostAddIcon />}
                </ListItemIcon>
                <ListItemText className="textcolor" primary={text} />
            </ListItem>
            </Link>
          ))}
          {["Reviews"].map((text, index) => (
            <Link to="/reviews" style={{ textDecoration: "none" }}>
            <ListItem button key={text}>
                <ListItemIcon>
                  {index % 2 === 0 ? <RateReviewIcon /> : <RateReviewIcon />}
                </ListItemIcon>
                <ListItemText className="textcolor" primary={text} />
            </ListItem>
            </Link>
          ))}
        </List>
      </Drawer>
    </div>
  );
}
