import {
  CREATE_ADMIN,
  ADMIN_LOGIN,
  GET_ALL_COMPANIES,
  DELETE_SERVICE_PROVIDER,
  DELETE_POST,
  DELETE_REVIEW,
  SUSPEND_SERVICE_PROVIDER,
  GET_ALL_INDIVIDUALS,
  GET_ALL_SERVICE_PROVIDERS,
  GET_ALL_POSTS,
  GET_ALL_REVIEWS,
  APPROVE_INDIVIDUAL,
  APPROVE_COMPANY,
  APPROVE_POST,
  APPROVE_REVIEW,
  APPROVE_SERVICE_PROVIDER,
  SUSPEND_SERVICE_SEEKER,
  GET_ALL_SERVICE_PROVIDERS_AOS,
  APPROVE_AOS,
  DELETE_AOS,
} from "../../Constants/constants";
import axios from "axios";
import { toast } from "react-toastify";

export const createAdmin = (data) => {
  const adminToken = localStorage.getItem("admin");
  const token = JSON.parse(adminToken).data.token;

  const dataResponse = axios
    .post(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/apexway/dashboard/create?`,
      data
    )
    .then(function (response) {
      toast.success(response?.data?.msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    })
    .catch(function (error) {
      if (
        error?.response?.data?.message &&
        error?.response?.data?.message?.includes(
          "You are not authorized to perform this action"
        )
      ) {
        localStorage.clear();
        window.location.reload();
      }
      console.log(error);
    });

  return {
    type: CREATE_ADMIN,
    payload: dataResponse,
  };
};

export const getAllCompanies = (data) => (dispatch) => {
  const adminToken = localStorage.getItem("admin");
  const token = JSON.parse(adminToken)?.data?.token;

  const dataResponse = axios
    .get(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/apexway/dashboard/companies/all`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then(function (response) {
      // toast.success(response?.data?.msg, {
      //   position: "top-right",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      // });

      dispatch({
        type: GET_ALL_COMPANIES,
        payload: response.data,
      });
    })
    .catch(function (error) {
      if (
        error?.response?.data?.message &&
        error?.response?.data?.message?.includes(
          "You are not authorized to perform this action"
        )
      ) {
        localStorage.clear();
        window.location.reload();
      }
      console.log(error);
    });
};

export const getAllIndividuals = (data) => (dispatch) => {
  const adminToken = localStorage.getItem("admin");
  const token = JSON.parse(adminToken).data.token;

  const dataResponse = axios
    .get(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/apexway/dashboard/individuals/all`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then(function (response) {
      // toast.success(response?.data?.msg, {
      //   position: "top-right",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      // });

      dispatch({
        type: GET_ALL_INDIVIDUALS,
        payload: response.data,
      });
    })
    .catch(function (error) {
      if (
        error?.response?.data?.message &&
        error?.response?.data?.message?.includes(
          "You are not authorized to perform this action"
        )
      ) {
        localStorage.clear();
        window.location.reload();
      }
    });
};

export const filterIndividuals = (value) => (dispatch) => {
  const adminToken = localStorage.getItem("admin");
  const token = JSON.parse(adminToken).data.token;
  console.log("value === ", value);
  const dataResponse = axios
    .post(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/apexway/dashboard/service-seekers/search`,
      {
        search: value,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then(function (response) {
      // toast.success(response?.data?.msg, {
      //   position: "top-right",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      // });

      dispatch({
        type: GET_ALL_INDIVIDUALS,
        payload: response.data,
      });
    })

    .catch(function (error) {
      if (
        error?.response?.data?.message &&
        error?.response?.data?.message?.includes(
          "You are not authorized to perform this action"
        )
      ) {
        localStorage.clear();
        window.location.reload();
      }
      dispatch({
        type: GET_ALL_INDIVIDUALS,
        payload: [],
      });
    });
};

export const getAllServiceProviders = (data) => (dispatch) => {
  const adminToken = localStorage.getItem("admin");
  const token = JSON.parse(adminToken).data.token;

  const dataResponse = axios
    .get(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/apexway/dashboard/service-providers/all`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then(function (response) {
      // toast.success(response?.data?.msg, {
      //   position: "top-right",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      // });

      dispatch({
        type: GET_ALL_SERVICE_PROVIDERS,
        payload: response.data,
      });
    })
    .catch(function (error) {
      if (
        error?.response?.data?.message &&
        error?.response?.data?.message?.includes(
          "You are not authorized to perform this action"
        )
      ) {
        localStorage.clear();
        window.location.reload();
      }
      console.log(error);
    });
};

export const filterServiceProviders = (value) => (dispatch) => {
  const adminToken = localStorage.getItem("admin");
  const token = JSON.parse(adminToken).data.token;
  console.log("value === ", value);
  const dataResponse = axios
    .post(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/apexway/dashboard/service-providers/search`,
      {
        search: value,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then(function (response) {
      console.log(response);
      // toast.success(response?.data?.msg, {
      //   position: "top-right",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      // });

      dispatch({
        type: GET_ALL_SERVICE_PROVIDERS,
        payload: response.data,
      });
    })

    .catch(function (error) {
      if (
        error?.response?.data?.message &&
        error?.response?.data?.message?.includes(
          "You are not authorized to perform this action"
        )
      ) {
        localStorage.clear();
        window.location.reload();
      }
      dispatch({
        type: GET_ALL_SERVICE_PROVIDERS,
        payload: [],
      });
    });
};

export const getAllServiceProvidersAOS = (data) => (dispatch) => {
  const adminToken = localStorage.getItem("admin");
  const token = JSON.parse(adminToken).data.token;

  const dataResponse = axios
    .get(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/apexway/dashboard/service-providers/sp-requests`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then(function (response) {
      // toast.success(response?.data?.msg, {
      //   position: "top-right",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      // });

      dispatch({
        type: GET_ALL_SERVICE_PROVIDERS_AOS,
        payload: response.data,
      });
    })
    .catch(function (error) {
      if (
        error?.response?.data?.message &&
        error?.response?.data?.message?.includes(
          "You are not authorized to perform this action"
        )
      ) {
        localStorage.clear();
        window.location.reload();
      }
      console.log(error);
    });
};

export const filterServiceProvidersAOS = (data) => (dispatch) => {
  const adminToken = localStorage.getItem("admin");
  const token = JSON.parse(adminToken).data.token;

  const dataResponse = axios
    .get(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/apexway/dashboard/service-providers/sp-requests/search`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then(function (response) {
      // toast.success(response?.data?.msg, {
      //   position: "top-right",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      // });

      dispatch({
        type: GET_ALL_SERVICE_PROVIDERS_AOS,
        payload: response.data,
      });
    })
    .catch(function (error) {
      dispatch({
        type: GET_ALL_SERVICE_PROVIDERS_AOS,
        payload: [],
      });
      if (
        error?.response?.data?.message &&
        error?.response?.data?.message?.includes(
          "You are not authorized to perform this action"
        )
      ) {
        localStorage.clear();
        window.location.reload();
      }
      console.log(error);
    });
};

export const getAllPosts = (data) => (dispatch) => {
  const adminToken = localStorage.getItem("admin");
  const token = JSON.parse(adminToken).data.token;

  const dataResponse = axios
    .get(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/apexway/dashboard/post/all`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then(function (response) {
      // toast.success(response?.data?.msg, {
      //   position: "top-right",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      // });

      dispatch({
        type: GET_ALL_POSTS,
        payload: response.data,
      });
    })
    .catch(function (error) {
      if (
        error?.response?.data?.message &&
        error?.response?.data?.message?.includes(
          "You are not authorized to perform this action"
        )
      ) {
        localStorage.clear();
        window.location.reload();
      }
      console.log(error);
    });
};

export const loginAdmin = (data) => {
  const dataResponse = axios
    .post(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/apexway/dashboard/login?`,
      data
    )
    .then(function (response) {
      toast.success("logged in successfully", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      localStorage.setItem("admin", JSON.stringify(response));

      setTimeout(() => {
        window.location.replace("/individuals");
      }, 1000);
    })
    .catch(function (error) {
      if (
        error?.response?.data?.message &&
        error?.response?.data?.message?.includes(
          "You are not authorized to perform this action"
        )
      ) {
        localStorage.clear();
        window.location.reload();
      }
      toast.error("Failed to Log in", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    });

  return {
    type: ADMIN_LOGIN,
    payload: dataResponse,
  };
};

export const approveIndividual = (id, row, type) => (dispatch) => {
  const adminToken = localStorage.getItem("admin");
  const token = JSON.parse(adminToken).data.token;
  console.log("type", type);

  if (type === "approved") {
    const data = {
      is_approved: true,
    };

    const dataResponse = axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/apexway/individual/approve?id=${id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        toast.success(response?.data?.msg, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        dispatch({
          type: APPROVE_INDIVIDUAL,
          payload: { response, id },
        });
      })
      .catch(function (error) {
        if (
          error?.response?.data?.message &&
          error?.response?.data?.message?.includes(
            "You are not authorized to perform this action"
          )
        ) {
          localStorage.clear();
          window.location.reload();
        }
        console.log("err", error);
        toast.error("Failed to APPROVE", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  } else {
    let data = {};

    let url = `${process.env.REACT_APP_SERVER_URL}/api/v1/apexway/dashboard/individuals/suspend`;
    data = {
      individuals: [id],
      status: true,
    };

    const adminToken = localStorage.getItem("admin");
    const token = JSON.parse(adminToken).data.token;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    const dataResponse = axios
      .post(url, data, {
        headers: headers,
      })
      .then(function (response) {
        toast.success(response?.data?.msg, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        dispatch({
          type: SUSPEND_SERVICE_SEEKER,
          payload: { response, id },
        });
      })
      .catch(function (error) {
        if (
          error?.response?.data?.message &&
          error?.response?.data?.message?.includes(
            "You are not authorized to perform this action"
          )
        ) {
          localStorage.clear();
          window.location.reload();
        }
        toast.error("Failed to Get", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  }
};

export const approveCompany = (id) => (dispatch) => {
  const adminToken = localStorage.getItem("admin");
  const token = JSON.parse(adminToken).data.token;

  const data = {
    is_approved: true,
  };

  const dataResponse = axios
    .post(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/apexway/company/approve?id=${id}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then(function (response) {
      toast.success(response?.data?.msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      dispatch({
        type: APPROVE_COMPANY,
        payload: { response, id },
      });
    })
    .catch(function (error) {
      if (
        error?.response?.data?.message &&
        error?.response?.data?.message?.includes(
          "You are not authorized to perform this action"
        )
      ) {
        localStorage.clear();
        window.location.reload();
      }
      toast.error("Failed to APPROVE", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    });
};

export const approveServiceprovider = (id) => (dispatch) => {
  const adminToken = localStorage.getItem("admin");
  const token = JSON.parse(adminToken).data.token;

  const data = {
    is_approved: true,
  };

  const dataResponse = axios
    .post(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/apexway/service-provider/approve?id=${id}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then(function (response) {
      toast.success(response?.data?.msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      dispatch({
        type: APPROVE_SERVICE_PROVIDER,
        payload: { response, id },
      });
    })
    .catch(function (error) {
      if (
        error?.response?.data?.message &&
        error?.response?.data?.message?.includes(
          "You are not authorized to perform this action"
        )
      ) {
        localStorage.clear();
        window.location.reload();
      }
      toast.error("Failed to APPROVE", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    });
};

export const approvePost = (id) => (dispatch) => {
  const adminToken = localStorage.getItem("admin");
  const token = JSON.parse(adminToken).data.token;

  const data = {
    is_approved: true,
  };

  const dataResponse = axios
    .post(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/apexway/post/approve?id=${id}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then(function (response) {
      toast.success(response?.data?.msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      dispatch({
        type: APPROVE_POST,
        payload: response,
      });
    })
    .catch(function (error) {
      if (
        error?.response?.data?.message &&
        error?.response?.data?.message?.includes(
          "You are not authorized to perform this action"
        )
      ) {
        localStorage.clear();
        window.location.reload();
      }
      toast.error("Failed to APPROVE", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    });
};

export const approveServiceproviderAOS = (id) => (dispatch) => {
  console.log("id in ==== ", id);
  const adminToken = localStorage.getItem("admin");
  const token = JSON.parse(adminToken).data.token;
  debugger;
  const dataResponse = axios
    .post(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/apexway/dashboard/service-providers/sp-requests-approve`,
      {
        record_id: id,
        approval: true,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then(function (response) {
      toast.success(response?.data?.msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      console.log("response in approveServiceproviderAOS ==== ", response);
      dispatch({
        type: APPROVE_AOS,
        payload: { id },
      });
    })
    .catch(function (error) {
      if (
        error?.response?.data?.message &&
        error?.response?.data?.message?.includes(
          "You are not authorized to perform this action"
        )
      ) {
        localStorage.clear();
        window.location.reload();
      }
      toast.error("Failed to APPROVE", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    });
};

export const deleteServiceproviderAOS = (id) => (dispatch) => {
  console.log("id in ==== ", id);
  const adminToken = localStorage.getItem("admin");
  const token = JSON.parse(adminToken).data.token;
  debugger;
  const dataResponse = axios
    .post(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/apexway/dashboard/service-providers/sp-requests-approve`,
      {
        record_id: id,
        approval: false,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then(function (response) {
      toast.success(response?.data?.msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      console.log("response in approveServiceproviderAOS ==== ", response);
      dispatch({
        type: DELETE_AOS,
        payload: { id },
      });
    })
    .catch(function (error) {
      if (
        error?.response?.data?.message &&
        error?.response?.data?.message?.includes(
          "You are not authorized to perform this action"
        )
      ) {
        localStorage.clear();
        window.location.reload();
      }
      toast.error("Failed to APPROVE", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    });
};

export const getAllIndividualById = (id) => (dispatch) => {
  const adminToken = localStorage.getItem("admin");
  const token = JSON.parse(adminToken).data.token;

  const dataResponse = axios
    .get(`${process.env.REACT_APP_SERVER_URL}/api/v1/apexway/individual/get`, {
      params: {
        id: id,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(function (response) {
      // toast.success(response?.data?.msg, {
      //   position: "top-right",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      // });

      dispatch({
        type: APPROVE_SERVICE_PROVIDER,
        payload: dataResponse,
      });
    })
    .catch(function (error) {
      if (
        error?.response?.data?.message &&
        error?.response?.data?.message?.includes(
          "You are not authorized to perform this action"
        )
      ) {
        localStorage.clear();
        window.location.reload();
      }
      // toast.error("Failed to Get", {
      //   position: "top-right",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      // });
    });
};

export const deleteServiceProvider = (id) => (dispatch) => {
  const adminToken = localStorage.getItem("admin");
  const token = JSON.parse(adminToken).data.token;

  const dataResponse = axios
    .delete(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/apexway/dashboard/service-providers/delete`,
      {
        data: {
          serviceProviders: [id],
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then(function (response) {
      toast.success(response?.data?.msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      dispatch({
        type: DELETE_SERVICE_PROVIDER,
        payload: response,
      });
    })
    .catch(function (error) {
      if (
        error?.response?.data?.message &&
        error?.response?.data?.message?.includes(
          "You are not authorized to perform this action"
        )
      ) {
        localStorage.clear();
        window.location.reload();
      }
      toast.error("Failed to Get", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    });
};


export const suspendServiceProvider = (id, user) => (dispatch) => {
  let url;
  let data = {};
  if (user.hasOwnProperty("company_type")) {
    url = `${process.env.REACT_APP_SERVER_URL}/api/v1/apexway/dashboard/companies/suspend`;
    data = {
      companies: [id],
      status: true,
    };
  } else if (user.hasOwnProperty("area_of_specialization")) {
    url = `${process.env.REACT_APP_SERVER_URL}/api/v1/apexway/dashboard/service-providers/suspend`;
    data = {
      serviceProviders: [id],
      status: true,
    };
  } else {
    url = `${process.env.REACT_APP_SERVER_URL}/api/v1/apexway/dashboard/individuals/suspend`;
    data = {
      individuals: [id],
      status: true,
    };
  }

  const adminToken = localStorage.getItem("admin");
  const token = JSON.parse(adminToken).data.token;
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  const dataResponse = axios
    .post(url, data, {
      headers: headers,
    })
    .then(function (response) {
      toast.success(response?.data?.msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      if (response?.data?.success) {
        dispatch({
          type: SUSPEND_SERVICE_PROVIDER,
          // payload: "",
        });
      }
    })
    .catch(function (error) {
      if (
        error?.response?.data?.message &&
        error?.response?.data?.message?.includes(
          "You are not authorized to perform this action"
        )
      ) {
        localStorage.clear();
        window.location.reload();
      }
      toast.error("Failed to Get", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    });
};

export const activeServiceProvider = (id, user) => (dispatch) => {
  console.log("user", user);
  let url;
  let data = {};
  if (user.hasOwnProperty("company_type")) {
    url = `${process.env.REACT_APP_SERVER_URL}/api/v1/apexway/dashboard/companies/suspend`;
    data = {
      companies: [id],
      status: false,
    };
  } else if (user.hasOwnProperty("area_of_specialization")) {
    url = `${process.env.REACT_APP_SERVER_URL}/api/v1/apexway/dashboard/service-providers/suspend`;
    data = {
      serviceProviders: [id],
      status: false,
    };
  } else {
    url = `${process.env.REACT_APP_SERVER_URL}/api/v1/apexway/dashboard/individuals/suspend`;
    data = {
      individuals: [id],
      status: false,
    };
  }

  const adminToken = localStorage.getItem("admin");
  const token = JSON.parse(adminToken).data.token;
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  const dataResponse = axios
    .post(url, data, {
      headers: headers,
    })
    .then(function (response) {
      toast.success(response?.data?.msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      if (response?.data?.success) {
        dispatch({
          type: SUSPEND_SERVICE_PROVIDER,
          // payload: "",
        });
      }
    })
    .catch(function (error) {
      if (
        error?.response?.data?.message &&
        error?.response?.data?.message?.includes(
          "You are not authorized to perform this action"
        )
      ) {
        localStorage.clear();
        window.location.reload();
      }
      toast.error("Failed to Get", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    });
};


export const deletePost = (id) => (dispatch) => {
  const adminToken = localStorage.getItem("admin");
  const token = JSON.parse(adminToken).data.token;

  const dataResponse = axios
    .delete(`${process.env.REACT_APP_SERVER_URL}/api/v1/apexway/post/`, {
      data: {
        posts: [id],
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(function (response) {
      toast.success(response?.data?.msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      dispatch({
        type: DELETE_POST,
        payload: response,
      });
    })
    .catch(function (error) {
      if (
        error?.response?.data?.message &&
        error?.response?.data?.message?.includes(
          "You are not authorized to perform this action"
        )
      ) {
        localStorage.clear();
        window.location.reload();
      }
      toast.error("Failed to Get", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    });
};

export const filterPosts = (value) => (dispatch) => {
  const adminToken = localStorage.getItem("admin");
  const token = JSON.parse(adminToken).data.token;
  console.log("value === ", value);
  const dataResponse = axios
    .post(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/apexway/dashboard/post/search/`,
      {
        search: value,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then(function (response) {
      // toast.success(response?.data?.msg, {
      //   position: "top-right",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      // });

      dispatch({
        type: GET_ALL_POSTS,
        payload: response.data,
      });
    })

    .catch(function (error) {
      if (
        error?.response?.data?.message &&
        error?.response?.data?.message?.includes(
          "You are not authorized to perform this action"
        )
      ) {
        localStorage.clear();
        window.location.reload();
      }
      dispatch({
        type: GET_ALL_POSTS,
        payload: [],
      });
    });
};

export const getAllReviews = (data) => (dispatch) => {
  const adminToken = localStorage.getItem("admin");
  const token = JSON.parse(adminToken).data.token;

  const dataResponse = axios
    .get(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/apexway/dashboard/reviews/all`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then(function (response) {
      // toast.success(response?.data?.msg, {
      //   position: "top-right",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      // });

      dispatch({
        type: GET_ALL_REVIEWS,
        payload: response.data,
      });
    })
    .catch(function (error) {
      if (
        error?.response?.data?.message &&
        error?.response?.data?.message?.includes(
          "You are not authorized to perform this action"
        )
      ) {
        localStorage.clear();
        window.location.reload();
      }
      console.log(error);
    });
};

export const filterReviews = (value) => (dispatch) => {
  const adminToken = localStorage.getItem("admin");
  const token = JSON.parse(adminToken).data.token;
  console.log("value === ", value);
  const dataResponse = axios
    .post(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/apexway/dashboard/reviews/search`,
      {
        search: value,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then(function (response) {
      // toast.success(response?.data?.msg, {
      //   position: "top-right",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      // });

      dispatch({
        type: GET_ALL_REVIEWS,
        payload: response.data,
      });
    })

    .catch(function (error) {
      if (
        error?.response?.data?.message &&
        error?.response?.data?.message?.includes(
          "You are not authorized to perform this action"
        )
      ) {
        localStorage.clear();
        window.location.reload();
      }
      dispatch({
        type: GET_ALL_REVIEWS,
        payload: [],
      });
    });
};

export const approveReview = (id) => (dispatch) => {
  const adminToken = localStorage.getItem("admin");
  const token = JSON.parse(adminToken).data.token;
  const data = {
    is_approved: true,
  };

  const dataResponse = axios
    .post(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/apexway/review/approve?id=${id}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then(function (response) {
      toast.success(response?.data?.msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      dispatch({
        type: APPROVE_REVIEW,
        payload: response,
      });
    })
    .catch(function (error) {
      if (
        error?.response?.data?.message &&
        error?.response?.data?.message?.includes(
          "You are not authorized to perform this action"
        )
      ) {
        localStorage.clear();
        window.location.reload();
      }
      toast.error("Failed to APPROVE", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    });
};

export const deleteReview = (id) => (dispatch) => {
  const adminToken = localStorage.getItem("admin");
  const token = JSON.parse(adminToken).data.token;

  const dataResponse = axios
    .delete(`${process.env.REACT_APP_SERVER_URL}/api/v1/apexway/review/`, {
      data: {
        review: [id],
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(function (response) {
      toast.success(response?.data?.msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      dispatch({
        type: SUSPEND_SERVICE_PROVIDER,
        payload: { response, id },
      });

      dispatch({
        type: DELETE_REVIEW,
        payload: response,
      });
    })
    .catch(function (error) {
      if (
        error?.response?.data?.message &&
        error?.response?.data?.message?.includes(
          "You are not authorized to perform this action"
        )
      ) {
        localStorage.clear();
        window.location.reload();
      }
      toast.error("Failed to Get", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    });
};
